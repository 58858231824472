import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { customerAdd } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import $ from "jquery";

import ToTop from "../includes/ToTop";
import { Helmet } from "react-helmet";
import Select from "react-select";

export default function AccountantIndex() {
  const state = useSelector((state) => state.stateVals);

  const { accountant_id } = state;

  const [loading, setLoading] = useState(false);

  const [UserData, setUserData] = useState([]);

  const [customerModelView, setCustomerModelView] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [EditId, setEditId] = useState("");
  const [StatesList, setStatesList] = useState([]);
  const [EmailChecking, setEmailChecking] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [groupNo, setgroupNo] = useState("");

  const getAccountant = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.getAccountantData();

      let res;

      if (responce.status === 200) {
        res = responce.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            Sitname: value.Sitname,
            submited: value.submited,
            Participants: value.Participants,
            totalCount: value.totalCount,

            jobNo: value.jobNo,
            bulk: value.bulk,
          });
        });

        setUserData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setUserData([]);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (accountant_id) {
      getAccountant();
    }

    // eslint-disable-next-line
  }, []);

  const viewCustomerModel = async (gpNo) => {
    setCustomerModelView([]);
    setTotalPages(0);
    setLoading(true);
    try {
      const responce = await AdminListService.viewCustomerInModel(gpNo, "1");

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,

            FirstName: value.FirstName,
            LastName: value.LastName,
            Address: value.Address,
            City: value.City,
            State: value.State,
            ZipCode: value.ZipCode,
            PhoneNumber: value.PhoneNumber,
            Email: value.Email,
            Denomination: value.Denomination,
            UserId: value.UserId,
          });
        });

        setCustomerModelView([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCustomerModelView([]);
      setLoading(false);
    }
  };

  const deleteAccountant = async (groupId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          const response = await AdminListService.deleteAccountantMain(groupId);
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              getAccountant();
            }, 1500);
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    });
  };

  const submitAccount = async (grpNo) => {
    try {
      setLoading(true);

      const response = await AdminListService.accountantSubmit(
        grpNo,
        accountant_id
      );

      if (response.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Submitted successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          getAccountant();
        }, 1500);
      }
    } catch (err) {
      console.log(err);

      setLoading(false);

      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const viewForVoucher = async (gpNo) => {
    setCustomerModelView([]);
    setTotalPages(0);
    setLoading(true);
    try {
      const responce = await AdminListService.viewCustomerInModel(gpNo, "1");

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,

            FirstName: value.FirstName,
            LastName: value.LastName,
            Address: value.Address,
            City: value.City,
            State: value.State,
            ZipCode: value.ZipCode,
            PhoneNumber: value.PhoneNumber,
            Email: value.Email,
            Denomination: value.Denomination,
            UserId: value.UserId,
            VoucherNumber: value.VoucherNumber,
            ClientCost: value.ClientCost,
            InvoiceNo: value.InvoiceNo,
            CreatedDate: value.CreatedDate,
          });
        });

        setCustomerModelView([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCustomerModelView([]);
      setLoading(false);
    }
  };

  const Table = ({ data }) => {
    return (
      <>
        <div className="table-responsive mt-3">
          <table className="table table-striped table-bordered W-100  align-middle">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Date Submitted </th>
                <th>Total Customers </th>
                <th>Remaining Customers for Vouchers </th>
                <th>Job No.</th>
                <th>Bulk</th>
                <th width="35%">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                <>
                  {data.map((el, index) => (
                    <tr key={index}>
                      <td>{el.Sitname}</td>
                      <td>{el.submited}</td>
                      <td>{el.totalCount}</td>
                      <td>{el.Participants}</td>
                      <td>{el.jobNo}</td>
                      <td>{el.bulk}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3 fs-6">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            data-bs-toggle="modal"
                            data-bs-target="#add_job_number"
                            title="Add Job Number"
                            onClick={() => {
                              viewForVoucher(el.groupNo);
                              setgroupNo(el.groupNo);
                            }}
                          >
                            <i className="bi bi-plus-lg"></i> View / Enter
                            Voucher Number
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-success"
                            data-bs-toggle="modal"
                            data-bs-target="#view_date_model"
                            title="View"
                            onClick={() => {
                              viewCustomerModel(el.groupNo);
                              setgroupNo(el.groupNo);
                              setStatesList([]);
                            }}
                          >
                            <i className="bi bi-pencil-fill"></i> Edit
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => {
                              deleteAccountant(el.groupNo);
                            }}
                          >
                            <i className="bi bi-trash-fill"></i> Delete
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary"
                            title="Submit Data"
                            onClick={() => {
                              submitAccount(el.groupNo);
                            }}
                          >
                            <i className="bi bi-upload"></i> Submit Data
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="7" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  // customer in accountant

  const deleteModelCustomer = async (cusId, gpNo) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          const response = await AdminListService.deleteSingleOsrCustomer(
            cusId
          );
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              getAccountant();
              viewCustomerModel(gpNo);
            }, 1500);
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    });
  };
  const viewCustomerModelPagination = (gpNo, e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);
      try {
        const responce = await AdminListService.viewCustomerInModel(
          gpNo,
          pageNo
        );

        let res;

        if (responce.status === 200) {
          res = responce.data.data.data;

          const results = [];

          res.map((value) => {
            return results.push({
              groupNo: value.groupNo,
              FirstName: value.FirstName,
              LastName: value.LastName,
              Address: value.Address,
              City: value.City,
              State: value.State,
              ZipCode: value.ZipCode,
              PhoneNumber: value.PhoneNumber,
              Email: value.Email,
              Denomination: value.Denomination,
              UserId: value.UserId,
              VoucherNumber: value.VoucherNumber,
              ClientCost: value.ClientCost,
              InvoiceNo: value.InvoiceNo,
              CreatedDate: value.CreatedDate,
            });
          });

          setCustomerModelView([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.data.total_records);
          setTotalPages(responce.data.data.total_pages);
          setCurrentPage(responce.data.data.current_page);

          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setCustomerModelView([]);
        setLoading(false);
      }
    };
    getPaginationData();
  };

  const getStatesNames = async () => {
    try {
      const responce = await AdminListService.getStates();

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          value: value.code,
          label: value.state_name,
        });
      });
      setStatesList([...results]);
    } catch (err) {
      console.log(err);
      setStatesList([]);
    }
  };

  const editOsrCustomer = async (cusId) => {
    setLoading(true);
    try {
      const response = await AdminListService.getOsrCustomerData(cusId);

      if (response.status === 200) {
        setLoading(false);
        getStatesNames();

        osrCustomerEdit.setValues({
          groupNo: response.data.data.groupNo,
          fname: response.data.data.FirstName,
          lname: response.data.data.LastName,
          address: response.data.data.Address,
          city: response.data.data.City,
          state: response.data.data.State,
          zip: response.data.data.ZipCode,
          email: response.data.data.Email,
          phone: response.data.data.PhoneNumber
            ? response.data.data.PhoneNumber
            : "",
          denomination: response.data.data.Denomination,
        });
      }
    } catch (err) {
      console.log(err);

      setLoading(false);
    }
  };

  const CustomerModelTable = ({ data }) => {
    return (
      <>
        <div className="table-responsive mt-3">
          <table className="table table-striped table-bordered W-100  align-middle">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name </th>
                <th>Address </th>
                <th>City </th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Denomination</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                <>
                  {data.map((el, index) => (
                    <tr key={index}>
                      <td>{el.FirstName}</td>
                      <td>{el.LastName}</td>
                      <td>{el.Address}</td>
                      <td>{el.City}</td>
                      <td>{el.State}</td>
                      <td>{el.ZipCode}</td>
                      <td>{el.PhoneNumber}</td>
                      <td>{el.Email}</td>
                      <td>{el.Denomination}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3 fs-6">
                          <button
                            className="text-warning open-model-btn "
                            data-bs-toggle="modal"
                            data-bs-target="#edit_customer_model"
                            title=""
                            onClick={() => {
                              setEditId(el.UserId);
                              editOsrCustomer(el.UserId);
                            }}
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </button>
                          <button
                            type="button"
                            className="text-danger open-model-btn"
                            onClick={() => {
                              deleteModelCustomer(el.UserId, el.groupNo);
                            }}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="10" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              className="justify-content-center"
              onChange={(e) => {
                viewCustomerModelPagination(groupNo, e);
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  // update customer in accountant

  const UpdateAccountantCustomer = async (values, action) => {
    if (emailError === "") {
      try {
        setLoading(true);

        const response = await AdminListService.updateCustomerOsr(
          values,
          accountant_id,
          EditId
        );

        if (response.status === 200) {
          setLoading(false);
          action.resetForm();

          Swal.fire({
            icon: "success",
            title: "Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          await setStatesList([]);

          setTimeout(() => {
            viewCustomerModel(values.groupNo);
          }, 1500);
        }
      } catch (err) {
        setLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const varifyEmail = async (e) => {
    setEmailError("");
    if (e.target.value !== "") {
      setEmailChecking(true);
      let response;
      try {
        response = await AdminListService.checkOsrCustomerEmail(
          e.target.value,
          EditId
        );

        if (response.status === 200) {
          setEmailError("");
          setEmailChecking(false);
        }
      } catch (err) {
        if (err.response?.status === 409) {
          setEmailError("Email already exists");
          setEmailChecking(false);
        }
      }
    }
  };
  var clickEvent = $.Event("click");

  const osrCustomerEdit = useFormik({
    initialValues: {
      groupNo: "",
      fname: "",
      lname: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      phone: "",
      denomination: "",
    },
    validationSchema: customerAdd,
    onSubmit: (values, action) => {
      if (emailError === "") {
        UpdateAccountantCustomer(values, action);
        $("#editCustomerModelBtn").trigger(clickEvent);
      }
    },
  });

  // const handleTdClick = (event, val, id, type) => {
  //   const target = event.target;

  //   const td = event.currentTarget;
  //   if (target.tagName.toLowerCase() !== "input") {
  //     setEditId(id);
  //     td.innerHTML = `<input type="text" value="${val}"/>`;
  //     // let input = td.querySelector("input");
  //     // input.focus();
  //   } else {
  //     if (type === "voucher") {
  //       let input = td.querySelector("input");
  //       input.addEventListener("blur", updateVoucher);
  //     } else if (type === "invoice") {
  //       let input = td.querySelector("input");

  //       input.addEventListener("blur", updateInvoice);
  //     } else if (type === "cost") {
  //       let input = td.querySelector("input");

  //       input.addEventListener("blur", updateCost);
  //     }
  //   }
  // };

  const updateVoucher = async (event) => {
    const input = event.currentTarget;

    const newVoucherNumber = input.value;

    if (newVoucherNumber === event.target.defaultValue) {
      setEditingCell(null);
    } else {
      if (newVoucherNumber.length >= 5 && newVoucherNumber.length <= 6) {
        try {
          const response = await AdminListService.voucherUpdate(
            newVoucherNumber,
            editingCell?.rowIndex,
            accountant_id
          );

          viewCustomerModelPagination(response.data.grpNo, currentPage);
          setEditingCell(null);
        } catch (err) {
          if (err.response?.status === 409) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: err?.response?.data?.message,
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          } else if (err.response?.status === 422) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: err?.response?.data?.message?.voucher[0],
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          }
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Voucher length should be 5 or 6 digits.",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }

    // Call API to update voucher number
  };

  const updateInvoice = async (event) => {
    const input = event.currentTarget;

    const newInvoiceNumber = input.value;

    if (newInvoiceNumber === event.target.defaultValue) {
      setEditingCell(null);
    } else {
      try {
        const response = await AdminListService.invoiceUpdate(
          newInvoiceNumber,
          editingCell?.rowIndex,
          accountant_id,
          groupNo
        );

        if (response.status === 200) {
          viewCustomerModelPagination(groupNo, currentPage);
          setEditingCell(null);
        }
      } catch (err) {
        setEditingCell(null);

        if (err.response?.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else if (err.response?.status === 422) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message?.invoice[0],
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    }

    // Call API to update voucher number
  };

  const updateCost = async (event) => {
    const input = event.currentTarget;

    const newCost = input.value;

    if (newCost === event.target.defaultValue) {
      setEditingCell(null);
    } else {
      try {
        const response = await AdminListService.costUpdate(
          newCost,
          editingCell?.rowIndex,
          accountant_id
        );

        if (response.status === 200) {
          viewCustomerModelPagination(groupNo, currentPage);
          setEditingCell(null);
        }
      } catch (err) {
        setEditingCell(null);
        if (err.response?.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else if (err.response?.status === 422) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message?.cost[0],
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    }

    // Call API to update voucher number
  };

  const [editingCell, setEditingCell] = useState(null);

  const handleEdit = async (e, rowIndex, type) => {
    if (e.target.tagName.toLowerCase() !== "input") {
      await setEditingCell({ rowIndex, type });

      document.getElementById(rowIndex + type)?.focus();
    }
  };

  const DataForVoucher = ({ data }) => {
    return (
      <>
        <div className="table-responsive mt-3">
          <table className="table table-striped table-bordered W-100  align-middle">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name </th>
                <th>Address </th>
                <th>City </th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Denomination</th>
                <th>Voucher Number </th>
                <th>Client Cost</th>
                <th>Invoice</th>
                <th>Date Created</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                <>
                  {data.map((el, index) => (
                    <tr key={index}>
                      <td>{el.FirstName}</td>
                      <td>{el.LastName}</td>
                      <td>{el.Address}</td>
                      <td>{el.City}</td>
                      <td>{el.State}</td>
                      <td>{el.ZipCode}</td>
                      <td>{el.PhoneNumber}</td>
                      <td>{el.Email}</td>
                      <td>{el.Denomination}</td>
                      {/* <td
                        contentEditable={isEditable}
                        onClick={handleInvoiceNumberClick}
                        onBlur={handleInvoiceNumberBlur}
                      >
                        {el.VoucherNumber}
                      </td> */}

                      <td onClick={(e) => handleEdit(e, el.UserId, "voucher")}>
                        {editingCell?.rowIndex === el.UserId &&
                        editingCell?.type === "voucher" ? (
                          <input
                            type="text"
                            defaultValue={el.VoucherNumber}
                            onBlur={(e) => updateVoucher(e)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                updateVoucher(e);
                              }
                            }}
                            id={el.UserId + "voucher"}
                          />
                        ) : (
                          el.VoucherNumber
                        )}
                      </td>

                      <td onClick={(e) => handleEdit(e, el.UserId, "cost")}>
                        {editingCell?.rowIndex === el.UserId &&
                        editingCell?.type === "cost" ? (
                          <input
                            type="number"
                            defaultValue={el.ClientCost}
                            onBlur={(e) => updateCost(e)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                updateCost(e);
                              }
                            }}
                            id={el.UserId + "cost"}
                          />
                        ) : (
                          el.ClientCost
                        )}
                      </td>
                      <td
                        onClick={(e) => handleEdit(e, el.UserId, "invoice")}
                        // onClick={(e) => {
                        //   handleTdClick(e, el.InvoiceNo, el.UserId, "invoice");
                        // }}
                      >
                        {editingCell?.rowIndex === el.UserId &&
                        editingCell?.type === "invoice" ? (
                          <input
                            type="number"
                            defaultValue={el.InvoiceNo}
                            onBlur={(e) => updateInvoice(e)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                updateInvoice(e);
                              }
                            }}
                            id={el.UserId + "invoice"}
                          />
                        ) : (
                          el.InvoiceNo
                        )}
                      </td>

                      <td>{el.CreatedDate}</td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="13" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              className="justify-content-center"
              onChange={(e) => {
                viewCustomerModelPagination(groupNo, e);
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const clearVoucher = async (grpNo) => {
    try {
      setLoading(true);

      const response = await AdminListService.voucherClear(grpNo);

      if (response.status === 200) {
        setLoading(false);

        viewForVoucher(grpNo);
      }
    } catch (err) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Some thing went wrong!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const clearInvoice = async (grpNo) => {
    try {
      setLoading(true);

      const response = await AdminListService.invoiceClear(grpNo);

      if (response.status === 200) {
        setLoading(false);

        viewForVoucher(grpNo);
      }
    } catch (err) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Some thing went wrong!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  return (
    <div className="wrapper">
      <Helmet>
        <title>Accountant Dashboard</title>
      </Helmet>
      <main className="page-content customer-contnent">
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-35">Welcome, Accountant</h5>
          </div>

          <div className="card-body">
            <p className="font-14">
              <strong>Below are the pending customer</strong>
            </p>
            <Table data={UserData} />
          </div>
        </div>
      </main>

      {/* models here3  */}

      <div
        className="modal fade"
        id="view_date_model"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <CustomerModelTable data={customerModelView} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="edit_customer_model"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="card-body">
              <div className="card border shadow-none w-100">
                <div className="card-body">
                  <div className="modal-header border-0 p-0 pb-2">
                    <h4 className="modal-title">Update Customer</h4>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-12 d-flex">
                      <form
                        className="row g-3"
                        onSubmit={osrCustomerEdit.handleSubmit}
                        noValidate
                      >
                        <div className="col-12">
                          <span>
                            <strong>
                              Please enter the information below, if you want to
                              add a customer one by one. Field(s) with * are
                              mandatory.
                            </strong>
                          </span>
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            First Name{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="fname"
                            value={osrCustomerEdit.values.fname || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.fname &&
                          osrCustomerEdit.touched.fname ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.fname}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Last Name <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="lname"
                            value={osrCustomerEdit.values.lname || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.lname &&
                          osrCustomerEdit.touched.lname ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.lname}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Address <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            name="address"
                            value={osrCustomerEdit.values.address || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.address &&
                          osrCustomerEdit.touched.address ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.address}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            City <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            value={osrCustomerEdit.values.city || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.city &&
                          osrCustomerEdit.touched.city ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.city}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            State <strong className="text-danger">*</strong>
                          </label>

                          {/* <select
                            className="form-select"
                            name="state"
                            value={osrCustomerEdit.values.state || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          >
                            <option value="">Select State</option>
                            {StatesList.map((el, index) => {
                              return (
                                <option key={index} value={el.value}>
                                  {el.label}
                                </option>
                              );
                            })}
                          </select> */}
                          {StatesList.length ? (
                            <Select
                              className="form-label"
                              placeholder={
                                osrCustomerEdit.values.state !== ""
                                  ? osrCustomerEdit.values.state
                                  : "Select State"
                              }
                              options={StatesList}
                              isSearchable={true}
                              isClearable={true}
                              name="state"
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  osrCustomerEdit.setFieldValue(
                                    "state",
                                    selectedOption.value
                                  );
                                } else {
                                  osrCustomerEdit.setFieldValue("state", "");
                                }
                              }}
                              onBlur={() =>
                                osrCustomerEdit.setFieldTouched("state", true)
                              }
                            />
                          ) : null}

                          {osrCustomerEdit.errors.state &&
                          osrCustomerEdit.touched.state ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.state}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Zip Code <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip Code"
                            name="zip"
                            value={osrCustomerEdit.values.zip || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.zip &&
                          osrCustomerEdit.touched.zip ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.zip}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">Phone Number </label>
                          <input
                            type="string"
                            className="form-control"
                            placeholder="Phone Number"
                            name="phone"
                            value={osrCustomerEdit.values.phone || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.phone &&
                          osrCustomerEdit.touched.phone ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.phone}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Email 
                          </label>
                          <strong className="text-danger"> *</strong>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={osrCustomerEdit.values.email || ""}
                            onChange={(e) => {
                              osrCustomerEdit.handleChange(e);
                              varifyEmail(e);
                            }}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.email &&
                          osrCustomerEdit.touched.email ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.email}
                            </span>
                          ) : emailError !== "" ? (
                            <span className="text-danger">{emailError}</span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Denomination{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Denomination"
                            name="denomination"
                            value={osrCustomerEdit.values.denomination || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.denomination &&
                          osrCustomerEdit.touched.denomination ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.denomination}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 text-end">
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="d-flex h-100 w-100 align-items-end mb-mob">
                                <button
                                  className="btn btn-primary d-none"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view_date_model"
                                  data-bs-dismiss="modal"
                                  id="editCustomerModelBtn"
                                ></button>
                                <button
                                  className="btn btn-primary w-100"
                                  disabled={EmailChecking}
                                  type="submit"
                                >
                                  {EmailChecking ? (
                                    <div
                                      className="spinner-border text-light"
                                      role="status"
                                    ></div>
                                  ) : null}
                                  Submit Data
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="add_job_number"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <DataForVoucher data={customerModelView} />

              <div className="text-end">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    clearVoucher(groupNo);
                  }}
                >
                  Clear Voucher Number
                </button>
                <button
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    clearInvoice(groupNo);
                  }}
                >
                  Clear Invoice Number
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToTop />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
