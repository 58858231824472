import React from "react";
import cityLook from "../Images/city_look.png";
function EmailBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={cityLook} alt="city-look.png" width="55" height="57" />
      </div>

      <div className="col-md-11">
        <h2>BE ON THE LOOK OUT!</h2>
        Once Redeemed, a link will be sent to your email within 24 hours from{" "}
        <br />
        <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL}>
          {process.env.REACT_APP_DESC_EMAIL}
        </a>{" "}
        and you will be able to use the funds immediately.
      </div>
    </div>
  );
}

export default EmailBox;
