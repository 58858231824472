import { React, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import HeaderLower from "./HeaderLower";
import ContentPopup from "./ContentPopup";

import { FaCheckCircle } from "react-icons/fa";
// import {assignmentCheck} from "@iconify-icons/zmdi/assignment-check";
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "./Footer";

import { Helmet } from "react-helmet";

function Thankyou(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Thank You";
  const navigate = useNavigate();

  var redemptionForm = secureLocalStorage.getItem("UserForm");

  var redemptionObject = redemptionForm ? JSON.parse(redemptionForm) : false;

  const [showPopup, setShowPopup] = useState(redemptionObject.popup);

  // FIXME
  localStorage.removeItem("returnthankyou", true);
  localStorage.removeItem("voucher", "");
  localStorage.removeItem("returnpage", false);
  localStorage.removeItem("userformopen");
  localStorage.removeItem("userform");

  const isVoucher = localStorage.getItem("Thankyou");
  const Voucher = localStorage.getItem("voucher");

  useEffect(() => {
    if (redemptionObject === false) {
      navigate("/");
    }
    if (isVoucher !== "true" && !Voucher) {
      localStorage.setItem("isVoucher", false);

      navigate("/");
    } else {
      localStorage.setItem("Thankyou", false);
    }
  }, []);

  // StorageService.destroyRedThankStorage();

  // const isVoucher = StorageService.getThankStatus();
  // const Voucher = StorageService.getVoucher();
  // if (
  //   isVoucher !== "true" ||
  //   Voucher === undefined ||
  //   props.history.location.zipcode === "undefined"
  // ) {
  //   StorageService.setVoucherStatus(false);
  //   return <Redirect to="/" />;
  // } else {
  //   StorageService.setThankStatus(false);
  // }
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        <HeaderLower />
        <div className="white-box thank">
          <h1 className="text-center">{process.env.REACT_APP_THANKYOUTITLE}</h1>
          {/* <BsFillCheckCircleFill/> */}
          {/* <Icon icon={BsFillBagCheckFill}/> */}
          {/* <Icon icon={assignmentCheck} /> */}
          <FaCheckCircle />
          <p>Thank You! {redemptionObject.name}</p>
          <ul className="info-data">
            <li key="uniqueId56">
              {redemptionObject.name} {redemptionObject.lname}
            </li>

            {redemptionObject?.city?.length > 0 && (
              <li key="23ff">{redemptionObject.city}</li>
            )}

            {redemptionObject?.address?.length > 0 && (
              <li key="uniqueId1">
                {redemptionObject?.state} {redemptionObject?.address}{" "}
                {redemptionObject?.zipcode}
              </li>
            )}

            {redemptionObject?.emailid?.length > 0 && (
              <li key="uniqueId1">{redemptionObject?.emailid}</li>
            )}
          </ul>
          <p>
            If you selected a Physical Master Card please allow 7-10 business
            days to receive. If you selected a Virtual Amazon Card expect a link
            will be sent to your email within 3-5 days from{" "}
            <a href={"mailto:" + process.env.REACT_APP_DESC_CONTACTUS}>
              <strong>{process.env.REACT_APP_DESC_EMAIL}</strong>
            </a>
          </p>
          <p>
            Please do not discard your certificate until you receive your reward
            card.
          </p>
          <p>
            If you have any questions, please call{" "}
            <span className="green">
              <a href={"tel:" + process.env.REACT_APP_DESC_CONTACTUS}>
                <strong>{process.env.REACT_APP_DESC_CONTACTUS}</strong>
              </a>
            </span>
          </p>

          <p>
            or email us at{" "}
            <span className="blue">
              <a href={"mailto:" + process.env.REACT_APP_DESC_DONORCARE}>
                {" "}
                <strong>{process.env.REACT_APP_DESC_DONORCARE}</strong>
              </a>
            </span>
          </p>
          <p> and one of our donor card specialists will get back to you.</p>
          <p>Enjoy your card!</p>
        </div>
      </div>
      {showPopup === "true" && <ContentPopup />}
      <Footer />
    </>
  );
}
export default Thankyou;
