import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";
import $ from "jquery";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

export default function ManageJob() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [limit, setLimit] = useState("20");

  const cardList = async (page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.jobList(page);

      if (responce.status === 200) {
        let res;
        if (Array.isArray(responce?.data?.data?.data)) {
          res = responce?.data?.data?.data;
        } else if (typeof responce?.data?.data?.data === "object") {
          res = Object.values(responce.data.data.data);
        }
        const results = [];
        // res.map((value) => {
        //   return results.push({
        //     groupNo: value.groupNo,
        //     card:
        //       value.card !== ""
        //         ? value.card.map((item) => {
        //             return { name: item };
        //           })
        //         : [{ name: "" }],
        //     certificates: value.certificates,
        //     companyName: value.companyName,
        //     expiration: value.expiration,
        //     jobNo: value.jobNo,
        //     precentage: value.precentage,
        //     start: value.start,
        //     total_certificates: value.total_certificates,
        //     used_certificates: value.used_certificates,

        //   });
        // });

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            companyName: value.companyName,
            jobNo: value.jobNo,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.companyName.toString().toLowerCase().includes(searchQuery) ||
          item.jobNo.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      cardList("1");
    }
  }, [adminAccessToken]);

  const allSelect = async () => {
    $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
  };

  const delSelected = async () => {
    var selectedRecords = await $(
      ".sort-ths tbody tr td input:checkbox:checked"
    )
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedRecords.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        confirmButtonColor: "#e72e2e",
        text: "Please select some record!",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete record",
        icon: "question",
        iconColor: "#e04f5d",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",

        confirmButtonText: "Yes",
        cancelButtonText: "Close",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);

          try {
            const response = await AdminListService.deletJobByGroup(
              selectedRecords
            );
            if (response.status === 200) {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Deleted Successfully!",
                showConfirmButton: false,
                timer: 1500,
              });

              setTimeout(() => {
                cardList("1");
              }, 1500);
            }
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              confirmButtonColor: "#e72e2e",
              text: "Something went wrong!",
            });
            setLoading(false);
          }
        }
      });
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths align-middle">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("")}
                className={getClassNamesFor("")}
              >
                <input
                  className="form-check-input slect_all"
                  type="checkbox"
                  value=""
                  onChange={allSelect}
                />
              </th>
              <th
                scope="col"
                onClick={() => requestSort("jobNo")}
                className={getClassNamesFor("jobNo")}
              >
                Job Number
              </th>

              <th
                scope="col"
                onClick={() => requestSort("companyName")}
                className={getClassNamesFor("companyName")}
              >
                Company Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("")}
                className={getClassNamesFor("")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={el.groupNo}
                      value={el.groupNo}
                    />
                  </td>
                  <td>{el.jobNo}</td>

                  <td>{el.companyName}</td>

                  <td>
                    <div className="d-flex align-items-center gap-2 fs-6">
                      <Link
                        className="text-warning"
                        to={`/job_edit/${el.groupNo}`}
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </Link>
                      <Link
                        className="text-success"
                        to={`/job_detail/${el.jobNo}/${el.groupNo}`}
                      >
                        <i className="bi bi-eye-fill"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={parseInt(rowsPerPage)}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
        {/* {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              cardList(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )} */}
      </div>
    );
  };

  //   <div className="table-responsive mt-3">
  //   <table className="table table-striped table-bordered W-100 sort-ths align-middle">
  //     <thead>
  //       <tr>
  //         <th
  //           scope="col"
  //           onClick={() => requestSort("")}
  //           className={getClassNamesFor("")}
  //         >
  //           <input
  //             className="form-check-input slect_all"
  //             type="checkbox"
  //             value=""
  //             onChange={allSelect}
  //           />
  //         </th>
  //         <th
  //           scope="col"
  //           onClick={() => requestSort("jobNo")}
  //           className={getClassNamesFor("jobNo")}
  //         >
  //           Job Number
  //         </th>
  //         <th
  //           width="350"
  //           scope="col"
  //           onClick={() => requestSort("total_certificates")}
  //           className={getClassNamesFor("total_certificates")}
  //         >
  //           Job Summary
  //         </th>
  //         <th
  //           scope="col"
  //           onClick={() => requestSort("card")}
  //           className={getClassNamesFor("card")}
  //         >
  //           Job Cards
  //         </th>

  //         <th
  //           scope="col"
  //           onClick={() => requestSort("companyName")}
  //           className={getClassNamesFor("companyName")}
  //         >
  //           Company Name
  //         </th>

  //         <th
  //           scope="col"
  //           onClick={() => requestSort("certificates")}
  //           className={getClassNamesFor("certificates")}
  //         >
  //           Certificates
  //         </th>

  //         <th
  //           scope="col"
  //           onClick={() => requestSort("")}
  //           className={getClassNamesFor("")}
  //         >
  //           Action
  //         </th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {items.length ? (
  //         items.map((el, index) => (
  //           <tr key={index}>
  //             <td>
  //               <input
  //                 className="form-check-input"
  //                 type="checkbox"
  //                 id={el.groupNo}
  //                 value={el.groupNo}
  //               />
  //             </td>
  //             <td>{el.jobNo}</td>
  //             <td>
  //               <table className="inner-job-table">
  //                 <tbody>
  //                   <tr>
  //                     <td>Total Certificate</td>
  //                     <td>{el.total_certificates}</td>
  //                   </tr>
  //                   <tr>
  //                     <td>Total Used Certificates</td>
  //                     <td>{el.used_certificates}</td>
  //                   </tr>
  //                   <tr>
  //                     <td>Total Percentage used certificates</td>
  //                     <td>{el.precentage}</td>
  //                   </tr>
  //                   <tr>
  //                     <td>Begin Redemption</td>
  //                     <td>{el.start}</td>
  //                   </tr>
  //                   <tr>
  //                     <td>Expiration</td>
  //                     <td className="text-danger">{el.expiration}</td>
  //                   </tr>
  //                 </tbody>
  //               </table>
  //             </td>
  //             <td>
  //               <div className="job-card-multi">
  //                 {el.card.map((el, index) =>
  //                   el.name.startsWith("http") ? (
  //                     <img
  //                       src={el.name}
  //                       alt="card logo"
  //                       className="card-logo-img"
  //                       key={index}
  //                     />
  //                   ) : el.name !== "" ? (
  //                     <div className="pirds pirds2" key={index}>
  //                       <span className="rdcrdtop">
  //                         <table border="0">
  //                           <tbody>
  //                             <tr>
  //                               <td>{el.name}</td>
  //                             </tr>
  //                           </tbody>
  //                         </table>
  //                       </span>
  //                       <span className="rdcrd">REWARD CARD</span>
  //                     </div>
  //                   ) : (
  //                     "N/A"
  //                   )
  //                 )}
  //               </div>
  //             </td>
  //             <td>{el.companyName}</td>
  //             <td>
  //               <div className="certi-logn">{el.certificates}</div>
  //             </td>

  //             <td>
  //               <div className="d-flex align-items-center gap-3 fs-6">
  //                 <Link
  //                   className="btn btn-sm btn-primary"
  //                   to={`/job_edit/${el.groupNo}`}
  //                 >
  //                   <i className="bi bi-pencil-fill"></i>
  //                 </Link>
  //               </div>
  //             </td>
  //           </tr>
  //         ))
  //       ) : (
  //         <tr>
  //           <td colSpan="7" className="text-center text-capitalize">
  //             No record found
  //           </td>
  //         </tr>
  //       )}
  //     </tbody>
  //   </table>
  //   {totalResults > limit && totalPages > 1 ? (
  //     <Pagination
  //       activePage={currentPage}
  //       itemsCountPerPage={parseInt(limit)}
  //       totalItemsCount={totalResults}
  //       onChange={(e) => {
  //         cardList(e);
  //       }}
  //       pageRangeDisplayed={8}
  //       itemClass="page-item"
  //       linkClass="page-link"
  //       firstPageText="First Page"
  //       lastPageText="Last Page"
  //     />
  //   ) : (
  //     ""
  //   )}
  // </div>

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Manage Job</h5>
            </div>

            <div className="card-body">
              <button
                className="btn btn-primary mt-2 btn-sm"
                onClick={delSelected}
              >
                <i className="bi bi-trash-fill"></i> Delete Selected Cards
              </button>
              <a
                href={process.env.REACT_APP_API_Link + "jobSummaryDownload"}
                className="btn btn-primary mt-2 btn-sm float-end"
              >
                <i className="bi bi-cloud-download-fill"></i> Export Jobs
                Summary
              </a>

              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>
              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
