import * as Yup from "yup";

export const signImSchema = Yup.object({
  username: Yup.string()
    .min(2, "User Name must greater than 2")
    .max(50, "Length exceeds")
    .required("Please enter the User Name"),
  password: Yup.string()
    .min(2, "Password must greater than 2")
    .max(100, "Length exceeds")
    .required("Please enter the Password"),
});

export const customerAdd = Yup.object({
  fname: Yup.string()
    .required("Please enter the first name")
    .max(100, "The first name must not be greater than 100 characters."),
  lname: Yup.string()
    .required("Please enter the last name")

    .max(100, "The last name must not be greater than 100 characters."),
  address: Yup.string()
    .required("Please enter the address")
    .max(500, "The address must not be greater than 500 characters."),
  city: Yup.string()
    .required("Please enter the city")
    .max(50, "The city must not be greater than 50 characters."),
  state: Yup.string()
    .required("Please select the state")
    .min(2, "Invalid")
    .max(2, "The state must not be greater than 2 characters."),
  email: Yup.string()
    .required("Please enter the email")
    .email()
    .max(50, "The email must not be greater than 50 characters."),
  zip: Yup.string()
    .matches(
      /^[a-zA-Z0-9-]+$/,
      "zip code must only contain letters and numbers"
    )
    .required("Please enter the zip code"),

  denomination: Yup.number()
    .required("Please enter the denomination")
    .min(1, "Invalid"),
  phone: Yup.string()
    .matches(/^[0-9- ]+$/, "Invalid phone number")
    .max(12, "The phone must not be greater than 12 characters."),
});

export const jobNumber = Yup.object({
  jobNo: Yup.string()
    .min(5, "Please enter 5 or 6 digit number")
    .max(6, "Please enter 5 or 6 digit number")
    .required("Please enter job number")
    .matches(/^[0-9]{1,6}$/, "Please enter 5 or 6 digit number"),
});

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() - 1);

export const cardForJob = Yup.object({
  card1: Yup.string()
    .required("Please select card 1.")
    .test("unique", "Card already selected", function (value) {
      return (
        value !== this.parent.card2 &&
        value !== this.parent.card3 &&
        value !== this.parent.card4 &&
        value !== this.parent.card5 &&
        value !== this.parent.card6 &&
        value !== this.parent.card7 &&
        value !== this.parent.card8 &&
        value !== this.parent.card9 &&
        value !== this.parent.card10 &&
        value !== this.parent.card11 &&
        value !== this.parent.card12
      );
    }),
  card2: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card3: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card4: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card5: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card6: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card7: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card8: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card9: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card10: Yup.string().test(
    "unique",
    "Card already selected",
    function (value) {
      return (
        value !== this.parent.card1 &&
        value !== this.parent.card2 &&
        value !== this.parent.card3 &&
        value !== this.parent.card4 &&
        value !== this.parent.card5 &&
        value !== this.parent.card6 &&
        value !== this.parent.card7 &&
        value !== this.parent.card8 &&
        value !== this.parent.card9 &&
        value !== this.parent.card11 &&
        value !== this.parent.card12
      );
    }
  ),
  card11: Yup.string().test(
    "unique",
    "Card already selected",
    function (value) {
      return (
        value !== this.parent.card1 &&
        value !== this.parent.card2 &&
        value !== this.parent.card3 &&
        value !== this.parent.card4 &&
        value !== this.parent.card5 &&
        value !== this.parent.card6 &&
        value !== this.parent.card7 &&
        value !== this.parent.card8 &&
        value !== this.parent.card9 &&
        value !== this.parent.card10 &&
        value !== this.parent.card12
      );
    }
  ),
  card12: Yup.string().test(
    "unique",
    "Card already selected",
    function (value) {
      return (
        value !== this.parent.card1 &&
        value !== this.parent.card2 &&
        value !== this.parent.card3 &&
        value !== this.parent.card4 &&
        value !== this.parent.card5 &&
        value !== this.parent.card6 &&
        value !== this.parent.card7 &&
        value !== this.parent.card8 &&
        value !== this.parent.card9 &&
        value !== this.parent.card10 &&
        value !== this.parent.card11
      );
    }
  ),
  redSdate: Yup.date()
    .min(tomorrow, "Redemption start date must be after yesterday.")
    .required("Please select redemption start date."),
  redEdate: Yup.string().required("Please select redemption end date."),
});

export const cardForExistJob = Yup.object({
  card1: Yup.string()
    .required("Please select card 1.")
    .test("unique", "Card already selected", function (value) {
      return (
        value !== this.parent.card2 &&
        value !== this.parent.card3 &&
        value !== this.parent.card4 &&
        value !== this.parent.card5 &&
        value !== this.parent.card6 &&
        value !== this.parent.card7 &&
        value !== this.parent.card8 &&
        value !== this.parent.card9 &&
        value !== this.parent.card10 &&
        value !== this.parent.card11 &&
        value !== this.parent.card12
      );
    }),
  card2: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card3: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card4: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card5: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card6: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card7: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card8 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card8: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card9 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card9: Yup.string().test("unique", "Card already selected", function (value) {
    return (
      value !== this.parent.card1 &&
      value !== this.parent.card2 &&
      value !== this.parent.card3 &&
      value !== this.parent.card4 &&
      value !== this.parent.card5 &&
      value !== this.parent.card6 &&
      value !== this.parent.card7 &&
      value !== this.parent.card8 &&
      value !== this.parent.card10 &&
      value !== this.parent.card11 &&
      value !== this.parent.card12
    );
  }),
  card10: Yup.string().test(
    "unique",
    "Card already selected",
    function (value) {
      return (
        value !== this.parent.card1 &&
        value !== this.parent.card2 &&
        value !== this.parent.card3 &&
        value !== this.parent.card4 &&
        value !== this.parent.card5 &&
        value !== this.parent.card6 &&
        value !== this.parent.card7 &&
        value !== this.parent.card8 &&
        value !== this.parent.card9 &&
        value !== this.parent.card11 &&
        value !== this.parent.card12
      );
    }
  ),
  card11: Yup.string().test(
    "unique",
    "Card already selected",
    function (value) {
      return (
        value !== this.parent.card1 &&
        value !== this.parent.card2 &&
        value !== this.parent.card3 &&
        value !== this.parent.card4 &&
        value !== this.parent.card5 &&
        value !== this.parent.card6 &&
        value !== this.parent.card7 &&
        value !== this.parent.card8 &&
        value !== this.parent.card9 &&
        value !== this.parent.card10 &&
        value !== this.parent.card12
      );
    }
  ),
  card12: Yup.string().test(
    "unique",
    "Card already selected",
    function (value) {
      return (
        value !== this.parent.card1 &&
        value !== this.parent.card2 &&
        value !== this.parent.card3 &&
        value !== this.parent.card4 &&
        value !== this.parent.card5 &&
        value !== this.parent.card6 &&
        value !== this.parent.card7 &&
        value !== this.parent.card8 &&
        value !== this.parent.card9 &&
        value !== this.parent.card10 &&
        value !== this.parent.card11
      );
    }
  ),
});

export const redemptionDateForExistJob = Yup.object({
  redSdate: Yup.date()
    .min(tomorrow, "Redemption start date must be after yesterday.")
    .required("Please select redemption start date."),
  redEdate: Yup.string().required("Please select redemption end date."),
});

export const updateCertiVlidation = Yup.object({
  jobNo: Yup.string()
    .min(5, "Please enter 5 or 6 digit number")
    .max(6, "Please enter 5 or 6 digit number")
    .required("Please enter job number")
    .matches(/^[0-9]{1,6}$/, "Please enter 5 or 6 digit number"),
  certiNo: Yup.string().required("Please enter certificate number 1"),
  // RedeemedOnDate: Yup.date().required("Please enter reedeemed on date"),

  custName: Yup.string().required("Please enter customer name"),
  fName: Yup.string().required("Please enter first name"),
  lName: Yup.string().required("Please enter last name"),
  // address: Yup.string().required("Please enter address "),
  // city: Yup.string().required("Please enter city"),
  // state: Yup.string().required("Please enter state"),
  zipCode: Yup.string().matches(
    /^[a-zA-Z0-9]+$/,
    "zip code must only contain letters and numbers"
  ),
  phone: Yup.string()
    .matches(/^[0-9-]+$/, "Invalid phone number")
    .max(12, "The phone must not be greater than 12 characters."),
  email: Yup.string().email().required("Please enter email"),
  certiStatus: Yup.string().required("Please enter certificate status"),
});

export const updateCertiPyp = Yup.object({
  jobNo: Yup.string()
    .min(5, "Please enter 5 or 6 digit number")
    .max(6, "Please enter 5 or 6 digit number")
    .required("Please enter job number")
    .matches(/^[0-9]{1,6}$/, "Please enter 5 or 6 digit number"),
  certiNo: Yup.string().required("Please enter certificate number 1"),
  denom: Yup.string().required("The denomination field is required"),

  custName: Yup.string().required("Please enter customer name"),
  fName: Yup.string().required("Please enter first name"),
  lName: Yup.string().required("Please enter last name"),
  address: Yup.string().required("Please enter address "),
  city: Yup.string().required("Please enter city"),
  state: Yup.string().required("Please enter state"),
  zipCode: Yup.string()
    .required("Please enter zip code")
    .matches(
      /^[a-zA-Z0-9]+$/,
      "zip code must only contain letters and numbers"
    ),
  phone: Yup.string()
    .required("Please enter phone")
    .matches(/^[0-9-]+$/, "Invalid phone number")
    .max(12, "The phone must not be greater than 12 characters."),
  email: Yup.string().email().required("Please enter email"),
  certiStatus: Yup.string().required("Please enter certificate status"),
});

export const addSite = Yup.object({
  siteName: Yup.string().required("The site name field is required."),
  userName: Yup.string().required("The username field is required."),
  password: Yup.string().required("The password field is required."),
  email: Yup.string().email().required("The email field is required."),
  pedType: Yup.string().required("The product type field is required."),
  url: Yup.string().required("The url field is required."),
  subject: Yup.string().required("The email subject field is required."),
});

export const addSubCom = Yup.object({
  siteName: Yup.string().required("The site name field is required."),
  userName: Yup.string().required("The username field is required."),
  password: Yup.string().required("The password field is required."),
  email: Yup.string().email().required("The email field is required."),
  pedType: Yup.string().required("The product type field is required."),
  parent_id: Yup.string().required("The parent site field is required."),
  subject: Yup.string().required("The email subject field is required."),
});

export const plasticAddSite = Yup.object({
  siteName: Yup.string().required("The site name field is required."),
  userName: Yup.string().required("The username field is required."),
  password: Yup.string().required("The password field is required."),
  email: Yup.string().email().required("The email field is required."),
  pedType: Yup.string().required("The product type field is required."),
  visio: Yup.string().required("The na-visio number field is required."),
  body: Yup.string().required("The email body field is required."),

  subject: Yup.string().required("The email subject field is required."),
});

export const osrAdd = Yup.object({
  osrName: Yup.string().required("The osr name field is required."),
  userName: Yup.string().required("The username field is required."),
  password: Yup.string().required("The password field is required."),
  email: Yup.string().email().required("The osr email field is required."),
});

export const accountantAdd = Yup.object({
  name: Yup.string().required("The name field is required."),
  userName: Yup.string().required("The username field is required."),
  password: Yup.string().required("The password field is required."),
  email: Yup.string().email().required("The email field is required."),
});

export const addState = Yup.object({
  name: Yup.string()
    .matches(/^[A-Za-z]+$/, "Only alphabets are allowed")
    .required("State Name field is required."),
  code: Yup.string()
    .matches(/^[A-Za-z]+$/, "Only alphabets are allowed")
    .min(2, "Minimum length must be 2.")
    .max(2, "Maximum length must be 2.")
    .required("State code field is required."),
});
export const addCard = Yup.object({
  name: Yup.string().required("The card name field is required.."),
});

export const randomCerti = Yup.object({
  certiLength: Yup.number()
    .min(1, "Certificates length must be greater than 0")
    .max(
      999999999999999999999999999999,
      "Certificates length should be at most 30 digits long"
    )
    .required("Please Enter Length Of the Certificates"),
});

export const searchCerti = Yup.object({
  certiNo: Yup.string()
    .max(11, "The certificate must not be greater than 11 characters.")
    .required("The card number field is required.."),
});

export const profile = Yup.object({
  name: Yup.string().required("This field is required."),
  passwordNew: Yup.string()
    .required("This field is required.")
    .oneOf([Yup.ref("password"), null], "Password must match"),
  password: Yup.string().required("This field is required."),
  passwordOld: Yup.string().required("This field is required."),
  email: Yup.string().email().required("This field is required."),
});
