import { React, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import "./style.css";
import "./Footer.css";
import "./Info_Section.css";
import "./Input_fields.css";
import "./Button.css";
import Footer from "./Footer";
import { Navigate, useNavigate } from "react-router-dom";
import LogService from "../services/log.service";
import StorageService from "../services/storage.service";
import { Helmet } from "react-helmet";
import Recaptcha from "react-google-recaptcha";

const UserformSurvey = (props) => {
  const [ans1, setAns1] = useState(" ");
  const [ans2, setAns2] = useState(" ");
  const [ans3, setAns3] = useState(" ");
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const [website, setWebsite] = useState(window.location.href);
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Customer Survey Form ";

  const navigate = useNavigate();

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);
  // FIXME
  localStorage.setItem("returnpage", true);
  localStorage.setItem("page", "/survey-form");

  // StorageService.setReturnStatus(true);
  // StorageService.setPage("/UserFormSurvey");

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    StorageService.setReturnStatus(true);
    StorageService.destroyStorage();
  };

  const handleChangeans1 = (event) => {
    const ans1 = event.target.value;
    setAns1(ans1);
    localStorage.setItem("ans1", ans1);
    // StorageService.setAns("ans1", ans1);
  };
  const handleChangeans2 = (event) => {
    const ans2 = event.target.value;
    setAns2(ans2);
    localStorage.setItem("ans2", ans2);
  };
  const handleChangeans3 = (event) => {
    const ans3 = event.target.value;
    setAns3(ans3);
    localStorage.setItem("ans3", ans3);
  };
  const verifyCallback = (response) => {
    let errors = {};
    if (response) {
      setCaptcha({
        captcha: true,
      });
    } else {
      setErrors(errors);
      return false;
    }
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      localStorage.setItem("cardIndex", "true");
      // StorageService.setFormStatus(true);
      if (captcha) {
        secureLocalStorage.setItem("Survey", {
          ans1: ans1,
          ans2: ans2,
          ans3: ans3,
        });
        navigate("/card-form");
      }
    } else {
      localStorage.setItem("userformopen", false);
      // StorageService.setFormStatus(false);
    }
  };

  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }

  const validate = () => {
    let errors = {};
    let formIsValid = true;

    // TODO Validation for fields
    // if (ans1 === " ") {
    //   formIsValid = false;
    //   errors["ans1"] = "Please select the option";
    //   setErrors(errors);
    //   return false;
    // }

    // if (ans2 === " ") {
    //   formIsValid = false;
    //   errors["ans2"] = "Please select the option";
    //   setErrors(errors);
    //   return false;
    // }
    // if (ans3 === " ") {
    //   formIsValid = false;
    //   errors["ans3"] = "Please select the option";
    //   setErrors(errors);
    //   return false;
    // }

    if (!captcha) {
      formIsValid = false;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      setErrors(errors);
      return false;
    }
    return formIsValid;
  };
  const onLoadRecaptcha = () => {};
  const Voucher = localStorage.getItem("voucher");
  const userform = localStorage.getItem("userform");
  if (userform === "true") {
    return <Navigate to="/card-form" />;
  }

  if (Voucher === undefined || Voucher == null) {
    localStorage.setItem("returnpage", false);
    localStorage.setItem("userform", false);
    localStorage.removeItem("page");
    localStorage.removeItem("ans1");
    localStorage.removeItem("ans2");
    localStorage.removeItem("ans3");
    return <Navigate to="/" />;
  }

  // const Voucher = StorageService.getVoucher();
  // const userform = StorageService.getUserformStatus();
  // if (userform === true) {
  //   return <Redirect to="/UserForm" />;
  // }

  <Navigate to="/" />;
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <div className="white-box form">
          <form
            name="userinfo"
            className="form-outer register clearfix"
            id="userinfo"
            onSubmit={submituserRegistrationForm}
            method="post"
            action=""
          >
            <div className="row">
              <h1 style={{ textAlign: "center" }}>
                {process.env.REACT_APP_SURVEYTITLE}
              </h1>
              <hr />
              <label>
                <b>1</b>. Did the reward card motivate you to donate at Blood
                Assurance? <span></span>
              </label>

              <label className="inline">
                <input
                  type="radio"
                  id="satisfaction_radio1"
                  name="satisfaction_radio1"
                  value="Yes "
                  onChange={handleChangeans1}
                />{" "}
                Yes
              </label>

              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio2"
                  name="satisfaction_radio2"
                  value="No"
                  onChange={handleChangeans1}
                />{" "}
                No
              </label>

              {/* <label className="inline ml-2">
                <div className="errorMsg">{errors.ans1}</div>
                <span style={{ color: "red" }} id="satisfaction_msg"></span>
              </label> */}
              <hr />

              <label>
                <b>2</b>. How did you hear about this promotion?
                <span></span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value=" Social Media"
                  onChange={handleChangeans2}
                />{" "}
                Social Media
              </label>

              <label className="inline ml-2">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="Website "
                  onChange={handleChangeans2}
                />{" "}
                Website
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="Email "
                  onChange={handleChangeans2}
                />{" "}
                Email
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="Text "
                  onChange={handleChangeans2}
                />{" "}
                Text
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="Phone call"
                  onChange={handleChangeans2}
                />{" "}
                Phone call
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="I didn’t know about it until I donated"
                  onChange={handleChangeans2}
                />{" "}
                I didn’t know about it until I donated
              </label>

              {/* <label className="inline ml-2">
                <div className="errorMsg">{errors.ans2}</div>
                <span style={{ color: "red" }} id="mattress_msg"></span>
              </label> */}
              <hr />

              <label>
                <b>3</b>. Comment:<span></span>
              </label>
              <textarea
                name="experience_radio"
                id="experience_radio"
                value={ans3}
                cols="95"
                style={errors.ans3 ? { border: "1px solid red" } : null}
                onChange={handleChangeans3}
              ></textarea>

              {/* <label className="inline ml-2">
                <div className="errorMsg">{errors.ans3}</div>
                <span style={{ color: "red" }} id="experience_msg"></span>
              </label> */}

              <p>
                This information is used only for Blood Assurance and will not
                be sold or used for marketing purposes.
              </p>
              <br />

              <div className="overflow-hider">
                <Recaptcha
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    onChange={onCaptchaChange}
                />
              </div>
              <div className="errorMsg">{errors.checkederrorcaptcha}</div>

              <span style={{ color: "red" }} id="response_msg"></span>
              <br />
            </div>
            <input
              // onClick={() => myTimeout()}
              type="submit"
              className="submit-btn survey"
              name="submit1"
              value="Submit and Move Next"
            />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default UserformSurvey;
